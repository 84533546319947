<template>
  <div>
    <banner :title="title" :subtitle="subtitle"></banner>
  </div>
</template>

<script>
import Banner from "../components/NormalBanner.vue";
export default {
  components: { Banner },
  data() {
    return {
      title: "代理合作",
      subtitle: "共同为中国的传统行业线上化信息化事业发光发热",
    };
  },
};
</script>

<style>
</style>